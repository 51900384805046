import React, {useContext} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, ButtonGroup, Col, Form, InputNumber, RadioGroup, Row} from "rsuite"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import {selectAssignmentById, setAssignmentAmount, setAssignmentPrice} from "../store/assignment-slice"
import {actionCol, anzahlCol, labelCol, percentageCol, priceCol, useStyles} from "./constants"
import DeleteButton from "./delete-button";
import AureliaContext from "../../utilities/aurelia-context";
import {CurrencyInput} from "../../form/rsuite-form";

export default function Assignment({id, origin, destination, percentage, calculatedSum, additionalInfo}) {
    const {currencyValueConverter, percentageValueConverter} = useContext(AureliaContext)
    const dispatch = useDispatch()
    const {assignment} = useStyles()
    const {price, amount} = useSelector(state => selectAssignmentById(state, id))

    function amountChange(amount) {
        dispatch(setAssignmentAmount(id, parseInt(amount)))
    }

    return (
        <Row className={assignment}>
            <Col {...labelCol}>
                <StatsLabel label={destination}/>

                {!!additionalInfo?.length && (
                    <div><small>{additionalInfo.join("; ")}</small></div>
                )}
            </Col>

            <Col  {...anzahlCol}>
                <Form.Control name="amount-multiplier" accepter={RadioGroup} inline onChange={v => amountChange(v)}>
                    <ButtonGroup>
                        <Button
                            appearance="ghost"
                            disabled={1 === amount}
                            onClick={() => amountChange(1)}
                        >1</Button>

                        {1 < destination?.amount && (
                            <Button
                                appearance="ghost"
                                disabled={amount === destination.amount}
                                onClick={() => amountChange(destination.amount)}
                            >{destination.amount}</Button>
                        )}
                    </ButtonGroup>

                    <InputNumber
                        className="custom-input-number"
                        value={amount}
                        onChange={amountChange}
                        max={999999}
                        min={1}
                        style={{width: "100px", marginLeft: "15px", marginRight: "15px"}}>
                    </InputNumber>
                </Form.Control>
            </Col>

            <Col {...priceCol}>
                <Form.Control
                    style={{width:"100%"}}
                    name={id}
                    accepter={CurrencyInput}
                    value={price}
                    onChange={price => dispatch(setAssignmentPrice(id, price))}
                    debounce={750}
                />
            </Col>

            <Col {...percentageCol}>
                {!!percentage && percentageValueConverter.toView(percentage)}
            </Col>

            <Col {...percentageCol}>
                {!!calculatedSum && `${currencyValueConverter.toView({
                    amount: price.amount / amount,
                    currency: price.currency
                })} × ${amount}`}
            </Col>

            <Col {...percentageCol}>
                {calculatedSum?.amount && currencyValueConverter.toView(calculatedSum)}
            </Col>

            <Col {...actionCol}>
                <DeleteButton origin={origin} id={id}/>
            </Col>
        </Row>
    )
}
